<template>
	<div>
		<CRow >
			<CCol>
			<CCard>
				<CCardHeader>
					<CRow>
						<CCol col=4>
							<h2 v-if="newProfileName === null">
								{{ profile.name }}
								<b-link title="Spremeni uporabniško ime"  @click.stop="click_changeProfileName" class="ml-3">
									<CIcon name="cil-pencil" size="lg"/>
								</b-link>
							</h2>
							<div v-else>
								<vue-loading :active.sync="isLoadingProfileNameChange" :is-full-page="false" loader="dots"/>
								<CInput
									type="text"
									v-model="newProfileName"
									required
									horizontal
									addInputClasses="font-weight-bold"
									class="mb-1 p-0">
									<template #append>
										<CButton color="primary" :disabled="isLoadingProfileNameChange" @click.stop="click_changeProfileNameSubmit" size="sm"><CIcon name="cil-check-alt"/></CButton>
										<CButton color="danger" :disabled="isLoadingProfileNameChange" @click.stop="click_changeProfileNameCancel" size="sm"><CIcon name="cil-x"/></CButton>
									</template>
								</CInput>
							</div>
						</CCol>
						<CCol>
						<h2><CBadge v-for="role in profile.roles" :key="role.id" color="primary" class="float-right mr-2">{{ role }}</CBadge></h2>
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody>
					<CRow>
						<CCol col=2>
							<user-change-avatar/>
						</CCol>
						<CCol class="m-2">
							<h3>Email: <i>{{ profile.email }}</i></h3>
							<h3 class="mt-3">Uporabnik ustvarjen dne: <i>{{ profile.created_at | viewDate }}</i></h3>
						</CCol>
						<CCol class="m-2">
							<h3>Dovoljenja:
								<i>
									<CListGroup class="mt-3">
										<CListGroupItem v-for="permission in profile.permissions" :key="permission.id"  class="d-flex justify-content-between align-items-center">
											{{ permission }} <CBadge color="primary" shape="pill"><CIcon name="cil-check-alt"/></CBadge>
										</CListGroupItem>
									</CListGroup>
								</i>
							</h3>
						</CCol>
						
					</CRow>
					<CRow class="float-right">
						<b-button style="margin-right:5px;" variant="primary" @click="click_changePassword()">Spremeni geslo</b-button>
					</CRow>
				</CCardBody>
			</CCard>
			</CCol>
		</CRow>
		<user-change-password-modal ref="userChangePasswordModal" @emit_userChangePassword="emit_userChangePassword"/>
		<vue-toastr ref="toastr"></vue-toastr>
	</div>
</template>

<script>
import UserChangePasswordModal from '@/views/modals/UserChangePassword';
import UserChangeAvatar from '@/views/components/UserChangeAvatar';
import { USER_CHANGE_PROFILE_NAME, USER_DETAILS } from "@/store/actions/users";

export default {
	name: 'Profil',
	components: {
		'user-change-password-modal': UserChangePasswordModal,
		'user-change-avatar': UserChangeAvatar
	},
	data () {
		return {
			newProfileName: null,
			isLoadingProfileNameChange: false
		}
	},
	computed: {
		profile() {
			return this.$store.getters.getProfile
		},
	},
	methods: {
		click_changePassword: function () {
			this.$refs.userChangePasswordModal.showModal();
		},
		click_changeProfileName: function () {
			this.newProfileName = this.profile.name;
		},
		click_changeProfileNameSubmit: function () {
			this.isLoadingProfileNameChange = true;
			this.$store.dispatch(USER_CHANGE_PROFILE_NAME, { name: this.newProfileName })
				.then((responseData) => {
					setTimeout(() => {
						this.isLoadingProfileNameChange = false;
						this.newProfileName = null;
					}, 1000);
				})
				.catch(error => {
					this.isLoadingProfileNameChange = false;
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						this.$refs.toastr.e('Napaka pri urejanju uporabniškega imena! (' + error.response.data.code + ')');
					} else {
						this.$refs.toastr.e('Napaka! Prosimo poizkusite kasneje.');
					}
				});
		},
		click_changeProfileNameCancel: function () {
			this.newProfileName = null;
		},
		emit_userChangePassword: function () {
			this.$refs.toastr.s('Geslo uspešno spremenjeno.');
		}
	},
	created: function () {
		this.$store.dispatch(USER_DETAILS);
	}
}
</script>