<template>
	<b-modal :no-close-on-backdrop="true" :hide-header-close="isLoading" ref="modal" title="Sprememba gesla" size="sm" class="modal-primary" @hidden="clearModalData">
		<div>
			<CRow>
				<CCol>
					<CInput
						label="Trenutno geslo"
						type="password"
						:is-valid="isPasswordValid"
						:invalid-feedback="passwordInvalidFeedback"
						v-model="formData.password"/>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<CInput
						label="Novo geslo"
						type="password"
						:is-valid="isNewPasswordValid"
						:invalid-feedback="newPasswordInvalidFeedback"
						v-model="formData.newPassword"/>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<CInput
						label="Potrditev novega gesla"
						type="password"
						:is-valid="isNewPasswordConfirmationValid"
						:invalid-feedback="newPasswordConfirmationInvalidFeedback"
						v-model="formData.newPasswordConfirmation"/>
				</CCol>
			</CRow>
			<CAlert v-show="message.text != null" :color="message.type">
				{{ message.text }}
			</CAlert>
		</div>
		<template slot="modal-footer" slot-scope="{ cancel }">
			<b-container fluid class="p-0">
				<b-row>
					<b-col align-self="end" class="ma_pos">
						<b-button :disabled="isLoading" style="margin-right:5px;" variant="secondary" @click="cancel()">Prekliči</b-button>
						<b-button :title="okButtonTitle" :disabled="okButton.disabled" variant="primary" @click.stop="onOkModal()">{{ okButton.label }}</b-button>
					</b-col>
				</b-row>
			</b-container>
		</template>
		<vue-loading :active.sync="isLoading" :is-full-page="false"/>
	</b-modal>
</template>

<script>
import { USER_CHANGE_PASSWORD } from "@/store/actions/users";

export default {
	name: 'user-password-change-modal',
	components: {
	},
	props: {
	},
	methods: {
		showModal: function () {
			this.$refs.modal.show();
		},
		onOkModal: function () {
			this.startProgress();
			const formData = this.formData;
			this.$store.dispatch(USER_CHANGE_PASSWORD, { formData })
				.then((responseData) => {
					this.stopProgress();
					this.$emit('emit_userChangePassword');
					this.$refs.modal.hide();
				})
				.catch(error => {
					this.stopProgress();
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						if (error.response.data.code === 702) {
							this.formData.password = '';
							this.showAlert('Napačno trenutno geslo!', this.ALERT_TYPE_ERROR);
						} else {
							this.showAlert('Napaka pri spreminjanju gesla! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						}
					} else {
						this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
					}
				});
		},
		clearModalData: function () {
			this.isLoading = false;
			this.okButton = {
				label: 'Spremeni geslo',
				progress: false,
				disabled: false
			};
			this.formData.password = '';
			this.formData.newPassword = '';
			this.formData.newPasswordConfirmation = '';
			this.showAlert();
		},
		startProgress: function () {
			this.isLoading = true;
			this.okButton.disabled = true;
			this.okButton.progress = true;
		},
		stopProgress: function () {
			this.isLoading = false;
			this.okButton.disabled = false;
			this.okButton.progress = false;
		},
		showAlert: function (message, type, seconds) {
			if (message && type && seconds) {
				this.message.type = type;
				this.message.text = message;
				setTimeout(() => {
					this.showAlert();
				}, seconds * 1000);
			} else if (message && type) {
				this.message.type = type;
				this.message.text = message;
			} else if (message) {
				this.message.type = 'primary';
				this.message.text = message;
			} else {
				this.message.type = null;
				this.message.text = null;
			}
		}
	},
	data: function () {
		return {
			isLoading: false,
			title: 'Sprememba gesla',
			okButton: {
				label: 'Shrani',
				progress: false,
				disabled: false
			},
			message: {
				type: null,
				text: null
			},
			ALERT_TYPE_SUCCESS: 'success',
			ALERT_TYPE_ERROR: 'danger',
			formData: {
				password: '',
				newPassword: '',
				newPasswordConfirmation: ''
			}
		}
	},
	watch: {
	},
	computed: {
		isPasswordValid() {
			if (!this.formData.password) {
				this.okButton.disabled = true;
				return null;
			} else if (this.formData.password && this.formData.password.length > 5) {
				this.okButton.disabled = false;
				return true;
			} else {
				this.okButton.disabled = true;
				return false;
			}
		},
		passwordInvalidFeedback() {
			return 'Geslo mora vsebovati vsaj 6 znakov.';
		},
		isNewPasswordValid() {
			if (!this.formData.newPassword) {
				this.okButton.disabled = true;
				return null;
			} else if (this.formData.newPassword && this.formData.newPassword.length > 5) {
				this.okButton.disabled = false;
				return true;
			} else {
				this.okButton.disabled = true;
				return false;
			}
		},
		newPasswordInvalidFeedback() {
			return 'Geslo mora vsebovati vsaj 6 znakov.';
		},
		isNewPasswordConfirmationValid() {
			if (!this.formData.newPassword || !this.formData.newPasswordConfirmation) {
				this.okButton.disabled = true;
				return null;
			} if (this.isNewPasswordValid && this.formData.newPassword === this.formData.newPasswordConfirmation) {
				this.okButton.disabled = false;
				return true;
			} else {
				this.okButton.disabled = true;
				return false;
			}
		},
		newPasswordConfirmationInvalidFeedback() {
			if (this.isNewPasswordValid) {
				return 'Gesli se ne ujemata';
			} else {
				return 'Geslo mora vsebovati vsaj 6 znakov.';
			}
		},
		okButtonTitle () {
			if (this.formData.password.length < 6 || !this.isNewPasswordValid || !this.isNewPasswordConfirmationValid) {
				return 'Neveljaven vnos';
			}
			return '';
		}
	},
	created () {
	}
}
</script>

<style scoped>
.ma_pos {
	text-align: end;
}
</style>