<template>
	<div>
		<b-row>
			<div class="avatar-preview">
				<vue-loading :active.sync="isLoadingChangeAvatar" :is-full-page="false"/>
				<img class="avatar avatar-md" v-if="imageDataPreview!=null" :src="imageDataPreview" :alt="profile.email">
				<user-avatar v-else/>
				<div class="actions p-3">
					<div class="mb-2" v-if="imageDataPreview"><b-badge variant="warning" >Potrdite zamenjavo slike</b-badge></div>
					<CButton v-if="showUploadDialog" title="Potrdi izbrano sliko" class=" mr-2" color="primary" @click.stop="click_changeAvatar" size="sm"><CIcon name="cil-check-alt"/></CButton>
					<CButton v-else title="Zamenjaj sliko" class=" mr-2 " color="primary" @click.stop="click_showUploadDialogToggle" size="sm"><CIcon name="cil-pencil"/></CButton>
					<CButton v-if="showUploadDialog" title="Prekliči zamenjavo" color="danger" @click.stop="click_showUploadDialogToggle" size="sm"><CIcon name="cil-x"/></CButton>
					<CButton v-else-if="profile.avatar" title="Odstrani profilno sliko" color="danger" @click.stop="removeAvatar" size="sm"><CIcon name="cil-trash"/></CButton>
				</div>
			</div>
		</b-row>
		<b-row v-if="showUploadDialog">
			<b-col>
				<b-form-file 
					v-on:change="previewImage"
					placeholder="Izberi ali povleci sliko..."
					accept=".jpg, .jpeg, .png"
					browse-text="Izberi"
					ref="avatar-input"
					size="sm"
					:file-name-formatter="formatFileName"/>
					<vue-loading :active.sync="isLoadingChangeAvatar" :is-full-page="false" loader="dots"/>
			</b-col>
		</b-row>
		<vue-toastr ref="toastr"></vue-toastr>
	</div>
</template>

<script>
import UserAvatar from '@/views/components/UserAvatar';
import { USER_CHANGE_AVATAR } from "@/store/actions/users";

export default {
	name: 'user-chane-avatar',
	components: {
		'user-avatar': UserAvatar
	},
	methods: {
		changeAvatar: function() {
			this.isLoadingChangeAvatar = true;
			const imageData = this.imageData;
			this.$store.dispatch(USER_CHANGE_AVATAR, { imageData })
				.then((responseData) => {
					setTimeout(() => {
						this.isLoadingChangeAvatar = false;
						this.imageDataPreview = null;
						this.imageData = null;
						this.showUploadDialog = false;
					}, 2000);
				})
				.catch(error => {
					this.isLoadingChangeAvatar = false;
					this.imageDataPreview = null;
					this.imageData = null;
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						this.$refs.toastr.e('Napaka pri spreminjanju avatarja! (' + error.response.data.code + ' - ' +  error.response.data.message + ')');
					} else {
						this.$refs.toastr.e('Napaka! Prosimo poizkusite kasneje.');
					}
				});
        },
		click_showUploadDialogToggle: function () {
			this.showUploadDialog = !this.showUploadDialog;
			this.imageDataPreview = null;
			this.imageData = null;
		},
		click_changeAvatar: function () {
			if (this.imageData) {
				this.changeAvatar();
			} else {
				this.$refs.toastr.e('Izberite sliko!');
			}
		},
		removeAvatar: function () {
			this.$bvModal.msgBoxConfirm('Želite odstraniti profilno sliko?', {
				title: 'Potrdite odstranjevanje proflne slike',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'DA',
				cancelTitle: 'NE',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.imageData = null;
					this.changeAvatar();
				}
			}).catch(err => {
				console.error(err);
			})
		},
		previewImage: function (event) {
			let input;
			if (event instanceof DragEvent) {
				this.imageData = event.dataTransfer.files[0];
				input = event.dataTransfer;
			} else {
				this.imageData = event.target.files[0];
				input = event.target;
			}
			if (input.files && input.files[0]) {
				if (input.files[0].type.toLowerCase().includes('image')) {
					if (input.files[0].size < 2097152) {
						let reader = new FileReader();
						reader.onload = (e) => {
							this.imageDataPreview = e.target.result;
						}
						reader.readAsDataURL(input.files[0]);
					} else {
						this.$refs['avatar-input'].reset();
						this.imageData = null;
						this.$refs.toastr.e('Slika je prevelika.');
					}
				} else {
					this.$refs['avatar-input'].reset()
					this.imageData = null;
					this.$refs.toastr.e('Naložite sliko formata jpg, jpeg ali png.');
				}
			}
		},
		formatFileName() {
			return 'Avatar';
		}
	},
	data: function () {
		return {
			showUploadDialog: false,
			isLoadingChangeAvatar: false,
			imageData: null,
			imageDataPreview: null,
		};
	},
	computed: {
		profile() {
			return this.$store.getters.getProfile
		},
	}
};
</script>
<style>
.avatar-preview {
	position: relative;
}
.avatar-preview .actions {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
}
</style>